<template>
  <div class="form--group unit--detail-section">
    <div class="left--col">
      <span>{{ $t('addListing.unitDetail') }}</span>
      <span class="required">*</span>
    </div>
    <div class="right--col">
      <div class="row my-0">
        <div
          class="single--field py-0"
          v-if="bedroomRule.allowed"
          :class="validation.hasError('bedroom') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.bedroom')"
            outlined
            :hide-details="true"
            type="number"
            v-model="bedroom"
            name="bedroom"
            :class="validation.hasError('bedroom') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('bedroom')">{{
            validation.firstError('bedroom')
          }}</span>
        </div>
        <div
          class="single--field py-0"
          v-if="bathroomRule.allowed"
          :class="validation.hasError('bathroom') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.bathroom')"
            outlined
            :hide-details="true"
            type="number"
            v-model="bathroom"
            name="bathroom"
            :class="validation.hasError('bathroom') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('bathroom')">{{
            validation.firstError('bathroom')
          }}</span>
        </div>
        <div
          class="single--field py-0"
          v-if="buildingSizeRule.allowed"
          :class="validation.hasError('buildingSize') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.buildingSize')"
            outlined
            :hide-details="true"
            type="number"
            v-model="buildingSize"
            name="building_size"
            :class="validation.hasError('buildingSize') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('buildingSize')">{{
            validation.firstError('buildingSize')
          }}</span>
        </div>
        <div
          class="single--field py-0"
          v-if="landSizeRule.allowed"
          :class="validation.hasError('landSize') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.landSize')"
            outlined
            :hide-details="true"
            type="number"
            v-model="landSize"
            name="land_size"
            :class="validation.hasError('landSize') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('landSize')">{{
            validation.firstError('landSize')
          }}</span>
        </div>
        <div
          class="single--field py-0"
          v-if="totalFloorRule.allowed"
          :class="validation.hasError('totalFloor') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.totalFloor')"
            outlined
            :hide-details="true"
            type="number"
            v-model="totalFloor"
            name="total_floor"
            :class="validation.hasError('totalFloor') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('totalFloor')">{{
            validation.firstError('totalFloor')
          }}</span>
        </div>
        <div
          class="single--field py-0"
          v-if="floorRule.allowed"
          :class="validation.hasError('floor') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.floor')"
            outlined
            :hide-details="true"
            v-model="floor"
            name="floor"
            :class="validation.hasError('floor') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('floor')">{{
            validation.firstError('floor')
          }}</span>
        </div>

        <div
          class="single--field py-0"
          v-if="electricalPowerRule.allowed"
          :class="validation.hasError('electricalPower') ? 'has-error' : ''"
        >
          <v-text-field
            :label="$t('addListing.placeholder.electricalPower')"
            outlined
            :hide-details="true"
            type="number"
            v-model="electricalPower"
            name="total_floor"
            :class="validation.hasError('electricalPower') ? 'is-invalid' : ''"
            class="basic--input input--margin"
          />
          <span class="val-error" v-if="validation.hasError('electricalPower')">{{
            validation.firstError('electricalPower')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { mapGetters, mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'unit-numbering-section',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      selectedPropertyType: (state) => state.listingForm.sect1.location.selectedPropertyType,
    }),
    ...mapGetters({
      floorRule: 'listingForm/sect2/detail/floorRule',
      bedroomRule: 'listingForm/sect2/detail/bedroomRule',
      bathroomRule: 'listingForm/sect2/detail/bathroomRule',
      landSizeRule: 'listingForm/sect2/detail/landSizeRule',
      totalFloorRule: 'listingForm/sect2/detail/totalFloorRule',
      buildingSizeRule: 'listingForm/sect2/detail/buildingSizeRule',
      electricalPowerRule: 'listingForm/sect2/detail/electricalPowerRule',
      hoekRule: 'listingForm/sect2/detail/hoekRule',
      furnishingStatusRule: 'listingForm/sect2/detail/furnishingStatusRule',
    }),
    electricalPower: {
      get() {
        return this.$store.state.listingForm.sect2.detail.electricalPower;
      },
      set(value) {
        return this.$store.commit(
          'listingForm/sect2/detail/SET_ELECTRICAL_POWER',
          this.cleanInput(value),
        );
      },
    },
    hoek: {
      get() {
        return this.$store.state.listingForm.sect2.detail.hoek;
      },
      set(value) {
        return this.$store.commit('listingForm/sect2/detail/SET_HOEK', value);
      },
    },
    bedroom: {
      get() {
        return this.$store.state.listingForm.sect2.detail.bedroom;
      },
      set(value) {
        return this.$store.commit('listingForm/sect2/detail/SET_BEDROOM', this.cleanInput(value));
      },
    },
    bathroom: {
      get() {
        return this.$store.state.listingForm.sect2.detail.bathroom;
      },
      set(value) {
        return this.$store.commit('listingForm/sect2/detail/SET_BATHROOM', this.cleanInput(value));
      },
    },
    buildingSize: {
      get() {
        return this.$store.state.listingForm.sect2.detail.buildingSize;
      },
      set(value) {
        return this.$store.commit(
          'listingForm/sect2/detail/SET_BUILDING_SIZE',
          this.cleanInput(value),
        );
      },
    },
    landSize: {
      get() {
        return this.$store.state.listingForm.sect2.detail.landSize;
      },
      set(value) {
        return this.$store.commit('listingForm/sect2/detail/SET_LAND_SIZE', this.cleanInput(value));
      },
    },
    totalFloor: {
      get() {
        return this.$store.state.listingForm.sect2.detail.totalFloor;
      },
      set(value) {
        return this.$store.commit(
          'listingForm/sect2/detail/SET_TOTAL_FLOOR',
          this.cleanInput(value),
        );
      },
    },
    floor: {
      get() {
        return this.$store.state.listingForm.sect2.detail.floor;
      },
      set(value) {
        return this.$store.commit('listingForm/sect2/detail/SET_FLOOR', this.cleanInput(value));
      },
    },
  },
  methods: {
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
  },

  watch: {
    selectedPropertyType() {
      this.validation.reset();
    },
  },
  validators: {
    electricalPower(value) {
      console.log('asdada,', this.electricalPowerRule);
      if (!this.electricalPowerRule.allowed || !this.electricalPowerRule.required) {
        console.log('tidak required');
        return null;
      }
      return Validator.value(value).required(this.$i18n.t('errors.electricalPower.required'));
    },
    bedroom(value) {
      if (!this.bedroomRule.allowed || !this.bedroomRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.bedroom.required'));
    },
    bathroom(value) {
      if (!this.bathroomRule.allowed || !this.bathroomRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.bathroom.required'));
    },
    buildingSize(value) {
      if (!this.buildingSizeRule.allowed || !this.buildingSizeRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.buildingSize.required'));
    },
    landSize(value) {
      if (!this.landSizeRule.allowed || !this.landSizeRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.landSize.required'));
    },
    totalFloor(value) {
      if (!this.totalFloorRule.allowed || !this.totalFloorRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.totalFloor.required'));
    },
    floor(value) {
      if (!this.floorRule.allowed || !this.floorRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.floor.required'));
    },
  },
};
</script>

<style scoped></style>
